import type { CrumbProps } from '../../types';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Link as LinkComponent } from '../Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { i18n, useTranslation } from 'next-i18next';
import { useAppContext } from '../../contexts/AppContext';
import { filterBreadcrumbs } from '../../utils/filter-breadcrumbs';

const Link = React.forwardRef<HTMLAnchorElement, any>((props, ref) =>
  useHighlightHandlers(LinkComponent, { forwardedRef: ref, ...props })
);

export function BreadCrumbs(props: CrumbProps) {
  const appContext = useAppContext();
  const { t } = useTranslation(['common', 'breadcrumb']);
  const breadcrumbFilter = props.breadcrumbs.map((breadcrumb) =>
    breadcrumb.path === "/"
      ? { ...breadcrumb, name: t("breadcrumb:home") }
      : breadcrumb
  );

  const { currentCollection } = appContext.useCollectionCrumbs();
  const { languageCode, componentOptions } = appContext.useConfig();
  const breadcrumbs = filterBreadcrumbs(
    componentOptions.component_use_collection_crumbs || false,
    breadcrumbFilter,
    languageCode,
    currentCollection,
  );

  const theme = useTheme();
  const crumbRefs = React.useRef<React.Ref<HTMLAnchorElement> | []>([]);

  // Todo: Fix crumbRefs.current typescript problem.
  // @ts-ignore
  crumbRefs.current = breadcrumbs.map((crumb, index: number) => {
    // @ts-ignore
    return crumbRefs.current[index] ?? React.createRef();
  });

  if (!props?.hidden)
    return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: theme.spacing(2, 0) }}>
        {breadcrumbs.map((crumb: any, index: number) =>
          breadcrumbs[breadcrumbs.length - 1] ? (
            <Link
              key={index}
              // @ts-ignore
              ref={crumbRefs.current[index]}
              underline="hover"
              color="text.primary"
              href={crumb.path}
              aria-current="page"
              variant="body2"
              sx={{ textTransform: "capitalize"}}
            >
              {crumb.name === 'Home' ? t('home', 'Home') : crumb.name}
            </Link>
          ) : (
            <Link
              key={index}
              // @ts-ignore
              ref={crumbRefs.current[index]}
              underline="hover"
              color="inherit"
              href={crumb.path}
              variant="body2"
              sx={{ textTransform: "capitalize"}}
            >
              {crumb.name}
            </Link>
          )
        )}
      </Breadcrumbs>
    );

  return <></>;
}
