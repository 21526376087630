import { translateUrl } from 'next-translate-routes';
import { CrumbProps } from '../types';

export function filterBreadcrumbs(
  useCollectionCrumbs: boolean,
  data: any,
  locale: string,
  currentCollection?: { name: string; slug: string }
) {
  const url = `${process.env.PROJECT_URL?.replace(/\/+$/, '')}`;
  const breadcrumbs = data;

  if (useCollectionCrumbs) {
    const filteredCrumbs = breadcrumbs.filter((crumb: any) => {
      return !crumb?.type?.includes("category");
    });

    if (currentCollection?.name && currentCollection?.slug) {
      return filteredCrumbs.map((crumb: any) => {
        const translatedCollection = translateUrl(`/collection/${crumb.code}`, locale);
        if (crumb.path.includes(translatedCollection)) {
          return {
            href: `${url}${translateUrl(`/collection/${currentCollection.slug}`, locale)}`,
            path: `${translateUrl(`/collection/${currentCollection.slug}`, locale)}`,
            name: currentCollection.name,
          };
        }
        return crumb;
      });
    }

    return filteredCrumbs;
  } else {
    return breadcrumbs.filter((crumb: any) => {
      const translatedCollection = translateUrl(`/collection/${crumb.code}`, locale);
      return !crumb.path.includes(translatedCollection);
    });
  }
}